import {Injectable, OnInit} from '@angular/core';
import {DataBaseService} from './data-base.service';
import {CommunicatorService} from '../communicator.service';
import {DataModelService} from '../data-model.service';
import {ILocation} from '../../interfaces/i-location';
import {ActivatedRoute} from '@angular/router';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class LocationService extends DataBaseService {

  protected _endPoint = new BehaviorSubject('locations');
  protected _model = 'LOCATIONS';

  constructor(protected _communicator: CommunicatorService<ILocation>,
              protected _dataModelService: DataModelService,
              protected _route: ActivatedRoute
  ) {
    super(_communicator, _dataModelService, _route);
    this.init();
  }


}
