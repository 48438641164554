<nav class="navigation-container">

  <div class="logo">
    <a routerLink="/administration/dashboard">
      <img src="assets/logo.png">
    </a>
  </div>
  <div class="nav-items d-none d-lg-flex">
    <ul class="nav">
      <li class="nav-item">
        <a class="nav-link" routerLink="dashboard" routerLinkActive="active">Dashboard</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['standardmedias/list/', 1]" routerLinkActive="active">Werberechte</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['marketresearchs/list/', 1]" routerLinkActive="active">Marktforschung</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['hs_parties/list/', 1]" routerLinkActive="active">Events</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['wg_parties/list/', 1]" routerLinkActive="active">WG-Party</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['sbas/list/', 1]" routerLinkActive="active">SBA</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['universities/list/', 1]" routerLinkActive="active">Universitäten</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['users/list/', 1]" routerLinkActive="active">Users</a>
      </li>
    </ul>
  </div>

  <div class="user d-none d-lg-flex">
    <div class="config">
      <span class="user-name user-name-light">{{user.username}}</span>
      <span class="logout" (click)="logout()">Logout</span>
    </div>

    <div class="user-pic-wrapper">
      <img [src]="setPhoto(user)" class="user-pic" >
    </div>
  </div>

  <div class="d-block d-lg-none ">
    <div class="nav-items ">
      <div class="open-close" (click)="SideBarOpened = !SideBarOpened">
        <i class="fa fa-bars nav-link"  aria-hidden="true"></i>
      </div>
      <div class="sidenav" [ngClass]="{active:SideBarOpened}">
        <div class="user">
          <span class="user-name">
            {{user.username}}
          </span>
          <div class="user-pic-wrapper">
            <img [src]="setPhoto(user)" class="user-pic" >
          </div>
        </div>
        <ul class="nav d-block border-top" >
          <li class="nav-item side-nav-item">
            <a class="nav-link side-nav-link" routerLink="dashboard" routerLinkActive="active">Dashboard</a>
          </li>
          <!--
          <li class="nav-item">
            <a class="nav-link" routerLink="medien" routerLinkActive="active">Medien</a>
          </li>
          <li class="nav-item">
            <a class="nav-link"[routerLink]="['cities/list/', 1]"  routerLinkActive="active">Cities</a>
          </li>
          <li class="nav-item">
            <a class="nav-link"  [routerLink]="['environments/list/', 1]" routerLinkActive="active">Environments</a>
          </li>
          -->
          <!--
          <li class="nav-item side-nav-item">
            <a class="nav-link side-nav-link" [routerLink]="['locations/list/', 1]" routerLinkActive="active">Locations</a>
          </li>
          -->
          <li class="nav-item side-nav-item">
            <a class="nav-link side-nav-link" [routerLink]="['wg_parties/list/', 1]" routerLinkActive="active">Wg-Partys</a>
          </li>
          <li class="nav-item side-nav-item">
            <a class="nav-link side-nav-link" [routerLink]="['hs_parties/list/', 1]" routerLinkActive="active">Hs-Partys</a>
          </li>
          <li class="nav-item side-nav-item">
            <a class="nav-link side-nav-link" [routerLink]="['sbas/list/', 1]" routerLinkActive="active">SBA's</a>
          </li>

          <li class="nav-item side-nav-item">
            <a class="nav-link side-nav-link" [routerLink]="['standardmedias/list/', 1]" routerLinkActive="active">Standardmedien</a>
          </li>
          <li class="nav-item side-nav-item">
            <a class="nav-link side-nav-link" [routerLink]="['marketresearchs/list/', 1]" routerLinkActive="active">Marktforschung</a>
          </li>

          <li class="nav-item side-nav-item">
            <a class="nav-link side-nav-link" [routerLink]="['universities/list/', 1]" routerLinkActive="active">Universitäten</a>
          </li>
          <li class="nav-item side-nav-item">
            <a class="nav-link side-nav-link" [routerLink]="['users/list/', 1]" routerLinkActive="active">Users</a>
          </li>
          <li>
            <span class="logout " (click)="logout()">Logout</span>
          </li>
        </ul>
      </div>
    </div>
  </div>

</nav>

<div class="spacer"></div>



