import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from './modules/shared/shared.module';
import {RoutingModule} from './modules/routing/routing.module';
import { UniversitySearchComponent } from './components/university-search/university-search.component';
import {TablesModule} from './modules/tables/tables.module';
import {OthersModule} from './modules/others/others.module';


@NgModule({
  imports: [
    BrowserModule,
    RoutingModule,
    TablesModule,
    OthersModule,
    SharedModule.forRoot(),
    NgbModule.forRoot()
  ],
  declarations: [
    AppComponent,
  ],
  providers: [],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
