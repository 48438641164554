import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class UserService {


  private _user: BehaviorSubject<any> = new BehaviorSubject<any>({});

  constructor() {
    if (!this.user.value.username) {
      this._getUser();
    }
  }

  public setUser(tmpUser) {
    if (!!tmpUser) {
      this.user.next(tmpUser);
    }
    this.user.next({
      username: '',
      id: '',
      active: '',
      created: '',
      modified: ''
    });
  }

  private _getUser() {
    const user = localStorage.getItem('user');
    if (!!user) {
      this.user.next(JSON.parse(user));
    }
  }


  get user() {
    return this._user;
  }

  set user(value) {
    this._user = value;
  }

}
