import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AppConstants} from '../../../app-constants';
import {UserService} from '../../../services/user.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';

@Component({
  selector: 'app-forgetpassword',
  templateUrl: './forgetpassword.component.html',
  styleUrls: ['./forgetpassword.component.scss']
})
export class ForgetpasswordComponent implements OnInit {

  public form: FormGroup = new FormGroup({
    email: new FormControl()
  });

  constructor(private _router: Router, private _http: HttpClient) {

  }

  ngOnInit() {
  }

  public reset() {
    console.log('reset');
    this._http
      .post(AppConstants.SERVER + AppConstants.USERS + '/passwordreset', this.form.value)
      .toPromise()
      .then((value) => {
        console.log(value);
      });
  }

}
