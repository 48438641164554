import {Component, OnDestroy, OnInit} from '@angular/core';

import {NavigationEnd, Router} from '@angular/router';
import {UserService} from '../../../services/user.service';
import {Subscription} from 'rxjs';
import {AppConstants} from '../../../app-constants';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnDestroy {


  SideBarOpened = false;
  private _user: any;
  private _subscriptions: Subscription[] = [];

  constructor(private _router: Router, private _userService: UserService) {
    this.user = this._userService.user.value;

    const subscription = this._router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.SideBarOpened = false;
      }
    });
    this._subscriptions.push(subscription);
  }

  ngOnDestroy() {
    this._subscriptions.forEach((value, index, array) => {
      value.unsubscribe();
    });
  }

  ngOnInit() {
  }


  public logout() {
    localStorage.clear();
    this._router.navigate(['/']);
  }

  get user(): any {
    return this._user;
  }

  set user(value: any) {
    this._user = value;
  }

  setPhoto(user) {
    return AppConstants.SERVERPATH + user.photo_dir + user.photo;
  }
}
