import {Injectable} from '@angular/core';
import {IEnvironment} from '../../interfaces/i-environment';
import {CommunicatorService} from '../communicator.service';
import {DataModelService} from '../data-model.service';
import {Subscription, BehaviorSubject} from 'rxjs';
import {ActivatedRoute} from '@angular/router';

@Injectable()
export abstract class DataBaseService {

  protected _subs: Subscription[] = [];
  protected _endPoint: BehaviorSubject<string> = new BehaviorSubject<string>('');
  protected _model = '';

  constructor(
    protected _communicator: CommunicatorService<IEnvironment>,
    protected _dataModelService: DataModelService,
    protected _route?: ActivatedRoute
  ) {

  }

  ngOnDestroy() {
    this._subs.forEach((value) => {
      value.unsubscribe();
    })
  }

  private _getDataItems(endpoint) {
    this._subs.push(
      this._communicator.get({
          endPoint: endpoint
        },
        this._dataModelService[this._model]
      ).subscribe((value) => {
        console.log(value);
      })
    )
  }

  public init() {


    this._subs.push(this._route.queryParams.subscribe(params => {
      console.log('params params');
      console.log(params);
      this._getDataItems(this.endPoint.value + '?page=' + params.page);
    }));
  }


  get endPoint(): BehaviorSubject<string> {
    return this._endPoint;
  }

  set endPoint(value: BehaviorSubject<string>) {
    this._endPoint = value;
  }

}
