import {Component, OnDestroy, OnInit} from '@angular/core';
import {IMessage, MessageService} from '../../services/message.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit, OnDestroy {

  private _messages: IMessage[] = [];
  private _subscriptions: Subscription[] = [];

  constructor(public messageService: MessageService) {
    const sub = this.messageService.observeMessages().subscribe((value) => {
      this.messages = value;
    });
    this._subscriptions.push(sub)
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    for (let i = 0; i < this._subscriptions.length; i++) {
      const sub = this._subscriptions[i];
      if(sub instanceof Subscription){
        sub.unsubscribe()
      }
    }
  }

  get messages(): IMessage[] {
    return this._messages;
  }

  set messages(value: IMessage[]) {
    this._messages = value;
  }

}
