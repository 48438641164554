import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from '@angular/router';
import {LoginComponent} from '../../login/login.component';
import {UmadComponent} from '../../projects/umad/umad.component';
import {AuthGuard} from '../../guards/auth.guard';
import {ForgetpasswordComponent} from '../../projects/umad/forgetpassword/forgetpassword.component';

const Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'help',
    component: ForgetpasswordComponent
  },
  {
    canActivate: [AuthGuard],
    path: 'administration',
    component: UmadComponent,
    loadChildren: 'app/projects/umad/umad.module#UmadModule'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(Routes/*,{enableTracing: true}*/)
  ],
  exports: [
    RouterModule
  ],
  declarations:
    []
})

export class RoutingModule {
}
