<div class="container-fluid">

  <form class="login" [formGroup]="form">
    <div class="spacer"></div>
    <div class="row">
      <div class="col d-flex justify-content-center">
        <img src="assets/logo.png" title="unicum" alt="logo">
      </div>
    </div>
    <div class="spacer"></div>
    <div class="spacer"></div>
    <div class="spacer"></div>
    <div class="row">
      <div class="col col-12">
        <div class="row justify-content-center">
          <div class="col-3">
            <div class="form-container">
              <label>Nutzer-Name</label>
              <input type="text" class="form-control" placeholder="Benutzername" formControlName="username">
            </div>
          </div>
        </div>
      </div>
      <div class="col col-12">
        <div class="row justify-content-center">
          <div class="col-3">
            <div class="form-container">
              <label>Passwort</label>
              <input type="password" class="form-control" placeholder="Passwort" formControlName="password">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="spacer"></div>
    <div class="row">
      <div class="col co-12">
        <div class="row justify-content-center">
          <div class="col-3">
            <button class="btn" (click)="login()">Login</button>
          </div>
        </div>
      </div>
    </div>

    <div class="spacer"></div>
    <div class="row">
      <div class="col d-flex justify-content-center">
        <a routerLink="help">Passwort vergessen?</a>
      </div>
    </div>
    <div class="spacer"></div>
  </form>

</div>
