import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SearchComponent} from '../../components/search/search.component';
import {ReactiveFormsModule} from '@angular/forms';
import {PagerComponent} from '../../components/pager/pager.component';
import {RouterModule} from '@angular/router';
import {NavigationComponent} from '../../projects/umad/navigation/navigation.component';
import {CsvUploaderComponent} from '../../components/csv-uploader/csv-uploader.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule
  ],
  declarations: [
    SearchComponent,
    PagerComponent,
    NavigationComponent,
    CsvUploaderComponent,
  ],
  exports: [
    SearchComponent,
    PagerComponent,
    NavigationComponent,
    CsvUploaderComponent,
  ]
})
export class OthersModule {
}
