import {Injectable} from '@angular/core';
import {CommunicatorService} from './communicator.service';
import {DataModelService} from './data-model.service';
import {isArray} from 'rxjs/util/isArray';
import {isObject} from 'rxjs/util/isObject';

@Injectable()
export class CitySearchService {


  constructor(
    protected _communicatorService: CommunicatorService<any>,
    protected _dataModelService: DataModelService
  ) {
  }

  private _city: string;


  public sendReq(search: string, callback?: Function) {

    this._communicatorService.post({
      city: search
    }, {
      endPoint: 'searches/index'
    }).subscribe((value) => {
      if (!!value.data.wg_parties) {
        this._dataModelService.DASHBOARD_WG_PARTIES.next(value.data.wg_parties);
      }
      if (!!value.data.hs_parties) {
        this._dataModelService.DASHBOARD_HS_PARTIES.next(value.data.hs_parties);
      }
      if (!!value.data.sbas) {
        this._dataModelService.DASHBOARD_SBAS.next(value.data.sbas);
      }
      if (!!value.data.standard_medias) {
        this._dataModelService.DASHBOARD_STANDARDMEDIAS.next(value.data.standard_medias);
      }
      if (!!value.data.market_researchs) {
        this._dataModelService.DASHBOARD_MARKET_RESEARCHS.next(value.data.market_researchs);
      }
      if (!!value.data.mediasum) {
        this._dataModelService.DASHBOARD_STANDARDMEDIAS_SUMMED.next(value.data.mediasum);
      }
      if (callback) {
        callback(value);
      }

      this.setData();
    });

  }


  public setData() {
    const hs = this._dataModelService.DASHBOARD_HS_PARTIES.value;
    const wg = this._dataModelService.DASHBOARD_WG_PARTIES.value;
    const media = this._dataModelService.DASHBOARD_STANDARDMEDIAS.value;
    const sba = this._dataModelService.DASHBOARD_SBAS.value;

    this._dataModelService.DASHBOARD_HS_PARTIES_FILTERED.next(hs);
    this._dataModelService.DASHBOARD_WG_PARTIES_FILTERED.next(wg);
    this._dataModelService.DASHBOARD_SBAS_FILTERED.next(sba);
    this._dataModelService.DASHBOARD_STANDARDMEDIAS_FILTERED.next(media);
  }

  get city(): string {
    return this._city;
  }

  set city(value: string) {
    this._city = value;
  }


}
