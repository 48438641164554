import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginComponent} from '../../login/login.component';
import {DataModelService} from '../../services/data-model.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {UmadComponent} from '../../projects/umad/umad.component';
import {DashboardComponent} from '../../projects/umad/dashboard/dashboard.component';
import {MediaComponent} from '../../projects/umad/media/media.component';
import {SbaComponent} from '../../projects/umad/sba/sba.component';
import {MessageService} from '../../services/message.service';
import {MessagesComponent} from '../../components/messages/messages.component';
import {BaseComponent} from '../../components/base/base.component';
import {CommunicatorService} from '../../services/communicator.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {HttpInterceptorService} from '../../interceptors/http-interceptor.service';
import {LoadingService} from '../../services/loading.service';
import {NotesComponent} from '../../components/notes/notes.component';
import {CitySearchComponent} from '../../components/city-search/city-search.component';
import {EnvironmentService} from '../../services/data-services/environment.service';
import {CitiesService} from '../../services/data-services/cities.service';
import {LocationService} from '../../services/data-services/location.service';
import {HsPartiesComponent} from '../../projects/umad/hs-parties/hs-parties.component';
import {WgPartiesComponent} from '../../projects/umad/wg-parties/wg-parties.component';
import {WgpartiesService} from '../../services/data-services/wgparties.service';
import {SbaAddComponent} from '../../projects/umad/sba/sba-add/sba-add.component';
import {WgPartyAddComponent} from '../../projects/umad/wg-parties/wg-party-add/wg-party-add.component';
import {HsPartyAddComponent} from '../../projects/umad/hs-parties/hs-party-add/hs-party-add.component';
import {BaseAddComponent} from '../../components/base-add/base-add.component';
import {FormPartComponent} from '../../components/form-parts/form-part/form-part.component';
import {ContactComponent} from '../../components/form-parts/contact/contact.component';
import {AddressComponent} from '../../components/form-parts/address/address.component';
import {LocationComponent} from '../../components/form-parts/location/location.component';
import {HsPartyEditComponent} from '../../projects/umad/hs-parties/hs-party-edit/hs-party-edit.component';
import {SbaEditComponent} from '../../projects/umad/sba/sba-edit/sba-edit.component';
import {WgPartyEditComponent} from '../../projects/umad/wg-parties/wg-party-edit/wg-party-edit.component';
import {BaseEditComponent} from '../../components/base-edit/base-edit.component';
import {LocationsResolverService} from '../../services/resolvers/locations-resolver.service';
import {IndexResolverService} from '../../services/resolvers/index-resolver.service';
import {ViewResolverService} from '../../services/resolvers/view-resolver.service';
import {AuthGuard} from '../../guards/auth.guard';
import {RightholderComponent} from '../../components/form-parts/rightholder/rightholder.component';
import {ContactsComponent} from '../../projects/umad/contacts/contacts.component';
import {EnvironmentComponent} from '../../components/form-parts/environment/environment.component';
import {CityComponent} from '../../components/form-parts/city/city.component';
import {CommentsComponent} from '../../projects/umad/comments/comments.component';
import {CommentEditComponent} from '../../projects/umad/comments/comment-edit/comment-edit.component';
import {CommentAddComponent} from '../../projects/umad/comments/comment-add/comment-add.component';
import {SocialmediasComponent} from '../../components/form-parts/socialmedias/socialmedias.component';
import {UserService} from '../../services/user.service';
import {MyDatePickerModule} from 'mydatepicker';
import {CitySearchService} from '../../services/city-search.service';
import {TmpSearchComponent} from '../../components/tmp-search/tmp-search.component';
import {UserAddComponent} from '../../projects/umad/users/user-add/user-add.component';
import {UserEditComponent} from '../../projects/umad/users/user-edit/user-edit.component';
import {UsersComponent} from '../../projects/umad/users/users.component';
import {MarketResearchsComponent} from '../../projects/umad/market-researchs/market-researchs.component';
import {StandardmediasComponent} from '../../projects/umad/standardmedias/standardmedias.component';
import {ResearchEditComponent} from '../../projects/umad/market-researchs/research-edit/research-edit.component';
import {ResearchAddComponent} from '../../projects/umad/market-researchs/research-add/research-add.component';
import {MediaEditComponent} from '../../projects/umad/standardmedias/media-edit/media-edit.component';
import {MediaAddComponent} from '../../projects/umad/standardmedias/media-add/media-add.component';
import {PasswordresetComponent} from '../../projects/umad/passwordreset/passwordreset.component';
import {ForgetpasswordComponent} from '../../projects/umad/forgetpassword/forgetpassword.component';
import {SafeHtmlPipe} from '../../pipe/safe-html.pipe';

import {TablesModule} from '../tables/tables.module';
import {OthersModule} from '../others/others.module';
import {UniversitiesComponent} from '../../projects/umad/universities/universities.component';
import {UniversityAddComponent} from '../../projects/umad/universities/university-add/university-add.component';
import {UniversityEditComponent} from '../../projects/umad/universities/university-edit/university-edit.component';
import {UniversitySearchComponent} from '../../components/university-search/university-search.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule,
    TablesModule,
    OthersModule,
    MyDatePickerModule,
  ],
  declarations: [
    LoginComponent,
    UmadComponent,
    DashboardComponent,
    MediaComponent,
    SbaComponent,
    MessagesComponent,
    BaseComponent,
    NotesComponent,
    CitySearchComponent,
    WgPartiesComponent,
    HsPartiesComponent,
    SbaAddComponent,
    WgPartyAddComponent,
    HsPartyAddComponent,
    BaseAddComponent,
    ContactComponent,
    FormPartComponent,
    AddressComponent,
    LocationComponent,
    HsPartyEditComponent, WgPartyEditComponent, SbaEditComponent,
    BaseEditComponent,
    RightholderComponent,
    ContactsComponent,
    CityComponent,
    EnvironmentComponent,
    CommentsComponent, CommentAddComponent, CommentEditComponent,
    SocialmediasComponent,
    TmpSearchComponent,
    UserAddComponent,
    UserEditComponent,
    UsersComponent,

    StandardmediasComponent,
    MarketResearchsComponent,
    MediaEditComponent, MediaAddComponent, ResearchEditComponent, ResearchAddComponent, PasswordresetComponent, ForgetpasswordComponent,

    UniversitySearchComponent,
    UniversitiesComponent,
    UniversityAddComponent,
    UniversityEditComponent,

    SafeHtmlPipe,
  ],
  exports: [
    LoginComponent,
    UmadComponent,
    DashboardComponent,
    MediaComponent,
    SbaComponent,
    MessagesComponent,
    BaseComponent,
    NotesComponent,
    WgPartiesComponent,
    HsPartiesComponent,
    SbaAddComponent,
    WgPartyAddComponent,
    HsPartyAddComponent,
    BaseAddComponent,
    ContactComponent,
    FormPartComponent,
    AddressComponent,
    LocationComponent,
    HsPartyEditComponent, WgPartyEditComponent, SbaEditComponent,
    BaseEditComponent,
    RightholderComponent,
    ContactsComponent,
    CityComponent,
    EnvironmentComponent,
    CommentsComponent, CommentAddComponent, CommentEditComponent,
    SocialmediasComponent,
    TmpSearchComponent,
    UserAddComponent,
    UserEditComponent,
    UsersComponent,
    StandardmediasComponent, MarketResearchsComponent,
    MediaEditComponent, MediaAddComponent, ResearchEditComponent, ResearchAddComponent, PasswordresetComponent, ForgetpasswordComponent,

    UniversitySearchComponent,
    UniversitiesComponent,
    UniversityAddComponent,
    UniversityEditComponent,
    SafeHtmlPipe,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        CommunicatorService,
        DataModelService,
        MessageService,
        LoadingService,
        EnvironmentService,
        LocationsResolverService,
        IndexResolverService,
        ViewResolverService,
        CitiesService,
        LocationService,
        WgpartiesService,
        AuthGuard,
        UserService,
        CitySearchService,
        SafeHtmlPipe,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpInterceptorService,
          multi: true,
        }
      ]
    };
  }
}
