import {Injectable} from '@angular/core';
import {DataModelService} from '../data-model.service';
import {CommunicatorService} from '../communicator.service';
import {IWgParty} from '../../interfaces/i-wg-party';
import {DataBaseService} from './data-base.service';
import {ActivatedRoute} from '@angular/router';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class WgpartiesService extends DataBaseService {

  protected _endPoint = new BehaviorSubject('wgparties');
  protected _model = 'WGPARTIES';

  constructor(protected _dataModelService: DataModelService,
              protected _communicator: CommunicatorService<IWgParty>,
              protected _route: ActivatedRoute) {
    super(_communicator, _dataModelService, _route);
    this.init();
  }

}
