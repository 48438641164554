import {Injectable} from '@angular/core';
import {ILocation} from '../interfaces/i-location';
import {IEnvironment} from '../interfaces/i-environment';
import {ICity} from '../interfaces/i-city';
import {IWgParty} from '../interfaces/i-wg-party';
import {IHsParty} from '../interfaces/i-hs-party';
import {BehaviorSubject} from 'rxjs';
import {IPagination} from '../interfaces/i-pagination';
import {ISba} from '../interfaces/i-sba';
import {IComment} from '../interfaces/i-comment';

@Injectable()
export class DataModelService {

  public LOCATIONS: BehaviorSubject<ILocation[]> = new BehaviorSubject<ILocation[]>([]);
  public LOCATION: BehaviorSubject<ILocation> = new BehaviorSubject<ILocation>({});
  public PAGINATION: BehaviorSubject<IPagination> = new BehaviorSubject<IPagination>({});

  public WG_PARTIES: BehaviorSubject<IWgParty[]> = new BehaviorSubject<IWgParty[]>([]);
  public WGPARTY: BehaviorSubject<IWgParty> = new BehaviorSubject<IWgParty>({});

  public UNIVERSITIES: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public UNIVERSITY: BehaviorSubject<any> = new BehaviorSubject<any>({});

  public USERS: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public USER: BehaviorSubject<any> = new BehaviorSubject<any>({});

  public STANDARDMEDIAS: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public STANDARDMEDIA: BehaviorSubject<any> = new BehaviorSubject<any>({});

  public MARKET_RESEARCHS: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public MARKET_RESEARCH: BehaviorSubject<any> = new BehaviorSubject<any>({});

  public COMMENTS: BehaviorSubject<IWgParty[]> = new BehaviorSubject<IComment[]>([]);
  public COMMENT: BehaviorSubject<IWgParty> = new BehaviorSubject<IComment>({});

  public CITIES: BehaviorSubject<ICity[]> = new BehaviorSubject<ICity[]>([]);
  public CITY: BehaviorSubject<ICity> = new BehaviorSubject<ICity>({});

  public HS_PARTIES: BehaviorSubject<IHsParty[]> = new BehaviorSubject<IHsParty[]>([]);
  public HSPARTY: BehaviorSubject<IHsParty> = new BehaviorSubject<IHsParty>({});

  public SBAS: BehaviorSubject<ISba[]> = new BehaviorSubject<ISba[]>([]);
  public SBA: BehaviorSubject<ISba> = new BehaviorSubject<ISba>({});

  public ENVIRONMENTS: BehaviorSubject<IEnvironment[]> = new BehaviorSubject<IEnvironment[]>([]);
  public ENVIRONMENT: BehaviorSubject<IEnvironment> = new BehaviorSubject<IEnvironment>({});


  public DASHBOARD_WG_PARTIES: BehaviorSubject<IWgParty[]> = new BehaviorSubject<IWgParty[]>([]);
  public DASHBOARD_SBAS: BehaviorSubject<ISba[]> = new BehaviorSubject<ISba[]>([]);
  public DASHBOARD_HS_PARTIES: BehaviorSubject<IHsParty[]> = new BehaviorSubject<IHsParty[]>([]);
  public DASHBOARD_MARKET_RESEARCHS: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public DASHBOARD_STANDARDMEDIAS: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public DASHBOARD_STANDARDMEDIAS_SUMMED: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);


  public DASHBOARD_WG_PARTIES_FILTERED: BehaviorSubject<IWgParty[]> = new BehaviorSubject<IWgParty[]>([]);
  public DASHBOARD_SBAS_FILTERED: BehaviorSubject<ISba[]> = new BehaviorSubject<ISba[]>([]);
  public DASHBOARD_HS_PARTIES_FILTERED: BehaviorSubject<IHsParty[]> = new BehaviorSubject<IHsParty[]>([]);
  public DASHBOARD_MARKET_RESEARCHS_FILTERED: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public DASHBOARD_STANDARDMEDIAS_FILTERED: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);


  public DASHBOARD_SEARCH: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor() {

  }

}
