<app-navigation></app-navigation>

<div class="container-fluid router-container">
  <router-outlet></router-outlet>
</div>


<div class="overlay-loading" *ngIf="overlayLoading">
  <div class="loading-text">
    ICH LADE GERADE....
  </div>
</div>
