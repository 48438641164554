
import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';


import {Router} from '@angular/router';
import {MessageService} from '../services/message.service';
import {isNullOrUndefined} from 'util';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {


  constructor(private _router: Router, private _messageService: MessageService) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    request = request.clone({
      setHeaders: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });

    if (!isNullOrUndefined(localStorage.getItem('token'))) {
      request = request.clone({
        setHeaders: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      });
    } else {

      if (window.location.pathname !== '/' && window.location.pathname !== '/help') {
        this._router.navigate(['']);
      }
    }

    return next.handle(request).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {

        }
      }),
      catchError(err => {
        console.log(err);
        if (err.status === 500) {
          this._messageService.addMessage({
            text: 'Error: 500',
            status: 'warning',
            errorCode: err.status,
            success: false
          });
        } else {

          this._messageService.addMessage({
            text: err.error.data.message,
            status: 'warning',
            errorCode: err.status,
            success: err.error.success
          });
        }

        console.log('Caught error', err);
        return observableThrowError(err);
      }),);
  }


}

