import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {DataModelService} from '../data-model.service';
import {HttpClient} from '@angular/common/http';
import {CommunicatorService} from '../communicator.service';

@Injectable()
export class ViewResolverService implements Resolve<any> {

  constructor(private _$http: HttpClient, private _dataModel: DataModelService, private _communicator: CommunicatorService<any>) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {

    return this._communicator
      .view(
        {
          endPoint: route.data['path'] + '/' + route.params['id']
        },
        this._dataModel[route.data['model']],
        (value) => {
          console.log(value);
        }
      );
  }
}
