import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TableComponent} from '../../components/table/table.component';
import {WgPartyTableComponent} from '../../components/table/tables/wg-party-table/wg-party-table.component';
import {HsPartyTableComponent} from '../../components/table/tables/hs-party-table/hs-party-table.component';
import {LocationTableComponent} from '../../components/table/tables/location-table/location-table.component';
import {SbaTableComponent} from '../../components/table/tables/sba-table/sba-table.component';
import {CityTableComponent} from '../../components/table/tables/city-table/city-table.component';
import {EnvironmentTableComponent} from '../../components/table/tables/environment-table/environment-table.component';
import {CommentTableComponent} from '../../components/table/tables/comment-table/comment-table.component';
import {TableStructureComponent} from '../../components/table-structure/table-structure.component';
import {UserTableComponent} from '../../components/table/tables/user-table/user-table.component';
import {StandardmediaTableComponent} from '../../components/table/tables/standardmedia-table/standardmedia-table.component';
import {MarketresearchTableComponent} from '../../components/table/tables/marketresearch-table/marketresearch-table.component';
import {EventTableComponent} from '../../components/table/tables/event-table/event-table.component';
import {MediaTableComponent} from '../../components/table/tables/media-table/media-table.component';
import {WgPartyDashboadTableComponent} from '../../components/table/tables/wg-party-dashboad-table/wg-party-dashboad-table.component';
import {SbaDashboardTableComponent} from '../../components/table/tables/sba-dashboard-table/sba-dashboard-table.component';
import {EventsDashboardTableComponent} from '../../components/table/tables/events-dashboard-table/events-dashboard-table.component';
import {UniversityTableComponent} from '../../components/table/tables/university-table/university-table.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {HttpClientModule} from '@angular/common/http';
import {MyDatePickerModule} from 'mydatepicker';
import {StandardmediaTableDashboardComponent} from '../../components/table/tables/standardmedia-dashboard-table/standardmedia-table-dashboard.component';
import {MarketresearchDashboardTableComponent} from '../../components/table/tables/marketresearch-dashboard-table/marketresearch-dashboard-table.component';
import {OthersModule} from '../others/others.module';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule,
    MyDatePickerModule,
    OthersModule,
  ],
  declarations: [
    TableComponent,
    WgPartyTableComponent,
    HsPartyTableComponent,
    LocationTableComponent,
    SbaTableComponent,
    CityTableComponent,
    EnvironmentTableComponent,
    CommentTableComponent,
    TableStructureComponent,
    UserTableComponent,
    StandardmediaTableComponent,
    MarketresearchTableComponent,
    EventTableComponent,
    MediaTableComponent,
    StandardmediaTableDashboardComponent,
    MarketresearchDashboardTableComponent,
    WgPartyDashboadTableComponent,
    SbaDashboardTableComponent,
    EventsDashboardTableComponent,
    UniversityTableComponent,

  ],
  exports: [
    TableComponent,
    WgPartyTableComponent,
    HsPartyTableComponent,
    LocationTableComponent,
    SbaTableComponent,
    CityTableComponent,
    EnvironmentTableComponent,
    CommentTableComponent,
    TableStructureComponent,
    UserTableComponent,
    StandardmediaTableComponent,
    MarketresearchTableComponent,
    EventTableComponent,
    MediaTableComponent,
    StandardmediaTableDashboardComponent,
    MarketresearchDashboardTableComponent,
    WgPartyDashboadTableComponent,
    SbaDashboardTableComponent,
    EventsDashboardTableComponent,

    UniversityTableComponent,
  ]
})
export class TablesModule { }
