<form [formGroup]="form" (submit)="reset()">
  <div class="spacer"></div>
  <div class="row">
    <div class="col d-flex justify-content-center">
      <img src="assets/logo.png" title="unicum" alt="logo">
    </div>
  </div>
  <div class="spacer"></div>
  <div class="spacer"></div>
  <div class="spacer"></div>
  <div class="row">
    <div class="col col-12">
      <div class="row justify-content-center">
        <div class="col-3">
          <div class="form-container">
            <label>E-Mail</label>
            <input type="text" class="form-control" placeholder="E-Mail" formControlName="email">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col co-12">
      <div class="row justify-content-center">
        <div class="col-3">
          <button class="btn">Reset Password</button>
        </div>
      </div>
    </div>
  </div>
  <div class="spacer"></div>
  <div class="row">
    <div class="col d-flex justify-content-center">
      <a routerLink="/">Login</a>
    </div>
  </div>
  <div class="spacer"></div>
</form>
