import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

export interface IMessage {
  text?: string;
  status?: string;
  errorCode?: string;
  success?: boolean;
}

@Injectable()
export class MessageService {

  private _messages: BehaviorSubject<Array<any>> = new BehaviorSubject([]);

  constructor() {
  }

  public observeMessages(): Observable<Array<any>> {
    return this._messages.asObservable();
  }

  public addMessage(message: IMessage) {
    this._messages.value.push(message);
    this._messages.next(this._messages.value);
    setTimeout(() => {
      this.deleteMessage(this._messages.value.indexOf(message));
    }, 3000);
  }

  public deleteMessage(index) {
    if (!index) {
      this._messages.value.shift();
    } else {
      this._messages.value.splice(index, 1);
    }
    this._messages.next(this._messages.value);
  }

}
