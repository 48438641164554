<div class="phi-messages-container">
  <ul>
    <li *ngFor="let message of messages; let i = index" [ngClass]="message.status">
      <span class="text">{{message.text}}</span>
      <span class="close-button">
          <i class="fa fa-close" (click)="messageService.deleteMessage(i)"></i>
      </span>
    </li>
  </ul>
</div>
