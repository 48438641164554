import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {AppConstants} from '../app-constants';
import {FormControl, FormGroup} from '@angular/forms';
import {UserService} from '../services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public form: FormGroup = new FormGroup({
    username: new FormControl(),
    password: new FormControl()
  });

  constructor(private _router: Router, private _http: HttpClient, private _userService: UserService) {

  }

  ngOnInit() {
  }

  public login() {

    const sub = this._http.post(AppConstants.SERVER + 'users/token', this.form.value).subscribe((value: any) => {

      if (value.success) {
        localStorage.setItem('token', value.data.token);
        localStorage.setItem('user', JSON.stringify(value.data.user));
        this._userService.setUser(value.data.user);
        this._router.navigate(['administration']);
      }
      sub.unsubscribe();


    });

  }

}
