import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {HttpClient, HttpParams} from '@angular/common/http';
import {DataModelService} from '../data-model.service';
import {CommunicatorService} from '../communicator.service';
import {IResponse} from '../../interfaces/i-response';

@Injectable()
export class IndexResolverService implements Resolve<any> {

  constructor(private _$http: HttpClient, private _dataModel: DataModelService, private _communicator: CommunicatorService<any>) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {

    const page = route.paramMap.get('page');
    const sort = route.paramMap.get('sort');
    const direction = route.paramMap.get('direction');
    const filter = route.paramMap.get('filter');

    const city = route.queryParamMap.get('city');
    const search = route.queryParamMap.get('search');


    let params = new HttpParams();
    params = params.append('page', page);
    if (!!sort) {
      params = params.append('sort', sort);
    }
    if (!!direction) {
      params = params.append('direction', direction);

    }
    if (!!filter) {
      params = params.append('approved', filter);
    }
    if (!!city) {
      params = params.append('city', city);
    }

    if (!!search) {
      params = params.append('search', search);
    }




    if (!!route.data['conditionModel']) {
      let id = route.paramMap.get('id');
      id = !id ? route.parent.paramMap.get('id') : id;
      params = params.append('model', route.data['conditionModel']);
      params = params.append('foreign_id', id);
    }

    return this._communicator.index(
      {
        endPoint: route.data['path'],
        params: params
      },
      this._dataModel[route.data['model']],
      (value: IResponse) => {
        window.scrollTo(0, 0);
        this._dataModel.PAGINATION.next(value.pagination);
      });
  }

}
