import {Injectable, OnDestroy} from '@angular/core';
import {CommunicatorService} from '../communicator.service';
import {DataModelService} from '../data-model.service';
import {IEnvironment} from '../../interfaces/i-environment';
import {DataBaseService} from './data-base.service';
import {ActivatedRoute} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {IMyDpOptions} from 'mydatepicker';


@Injectable()
export class EnvironmentService extends DataBaseService implements OnDestroy {

  protected _endPoint = new BehaviorSubject('environments');
  protected _model = 'ENVIRONMENTS';

  constructor(
    protected _communicator: CommunicatorService<IEnvironment>, protected _dataModelService: DataModelService, protected _route: ActivatedRoute) {
    super(_communicator, _dataModelService, _route);
    this.init();
  }


}
