import {Component, OnInit} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';

@Component({
  selector: 'app-umad',
  templateUrl: './umad.component.html',
  styleUrls: ['./umad.component.scss']
})
export class UmadComponent implements OnInit {


  private _overlayLoading = false;

  constructor(private _router: Router) {
    _router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        this.overlayLoading = true;
      }
      if (val instanceof NavigationEnd) {
        this.overlayLoading = false;
      }
    });
  }

  ngOnInit() {
  }

  get overlayLoading(): boolean {
    return this._overlayLoading;
  }

  set overlayLoading(value: boolean) {
    this._overlayLoading = value;
  }


}
