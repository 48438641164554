import {Injectable} from '@angular/core';
import {ILocation} from '../../interfaces/i-location';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable()
export class LocationsResolverService implements Resolve<ILocation> {


  constructor() {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ILocation> | Promise<ILocation> | ILocation {
    console.log('----------------------------');
    console.log(state.url, route.params);
    console.log('----------------------------');
    return undefined;
  }
}
