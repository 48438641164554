import {environment} from '../environments/environment';

export class AppConstants {
  public static readonly SERVER: string = environment.server;
  public static readonly SERVERPATH: string = environment.serverPath;
  public static readonly SBAS: string = 'sbas';
  public static readonly SBA: string = 'SBA';
  public static readonly USERS: string = 'users';

  public static readonly STANDARDMEDIAS: string = 'standardmedias';
  public static readonly UNIVERSITIES: string = 'universities';

  public static readonly MARKET_RESEARCHS: string = 'marketresearchs';



  public static readonly WGPARTIES: string = 'wg_parties';
  public static readonly WGPARTY: string = 'WGPARTY';

  public static readonly HSPARTIES: string = 'hs_parties';
  public static readonly HSPARTY: string = 'HSPARTY';


  public static readonly LOCATIONS: string = 'locations';
  public static readonly LOCATION: string = 'LOCATION';

  public static readonly CITIES: string = 'cities';
  public static readonly ENVIRONMENTS: string = 'environments';

  public static readonly COMMENTS: string = 'comments';



}
