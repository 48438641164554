import { Injectable } from '@angular/core';
import {CommunicatorService} from '../communicator.service';
import {ICity} from '../../interfaces/i-city';
import {DataBaseService} from './data-base.service';
import {DataModelService} from '../data-model.service';
import {ActivatedRoute} from '@angular/router';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class CitiesService extends DataBaseService {

  protected _endPoint = new BehaviorSubject('cities');
  protected _model = 'CITIES';

  constructor(protected _communicator: CommunicatorService<ICity>, protected _dataModelService: DataModelService, protected _route: ActivatedRoute) {
    super(_communicator, _dataModelService, _route);
    this.init();
  }

}
