
import {throwError as observableThrowError, Observable, BehaviorSubject} from 'rxjs';

import {catchError, finalize, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';




import {MessageService} from './message.service';
import {ICommunicatorOptions} from '../interfaces/i-communicator';
import {DataModel} from '../classes/data-model';
import {isArray} from 'rxjs/util/isArray';
import {IResponse} from '../interfaces/i-response';
import {DataModelService} from './data-model.service';
import {AppConstants} from '../app-constants';
import {FormControl, FormGroup} from '@angular/forms';
import {isObject} from 'rxjs/util/isObject';
import {isString} from 'util';

interface IMapAnswerOptions<T> {
  dataModel?: DataModel<T>;
  form?: FormGroup;
}


@Injectable()
export class CommunicatorService<T> {

  protected _serviceUrl = AppConstants.SERVER;

  constructor(protected _$http: HttpClient, protected _messageService: MessageService, protected _dataModelService: DataModelService) {

  }

  public get(options?: ICommunicatorOptions, dataModel?: DataModel<T>): Observable<any> {

    return this._$http.get<T>(this.serviceUrl + options.endPoint).pipe(
      map((value: any) => {
        this._mapAnswer(value as IResponse,
          {
            dataModel: dataModel
          }
        );
        return value;
      }),
      finalize(this._final),);


  }

  public index(options?: ICommunicatorOptions, dataModel?: BehaviorSubject<T[]>, callback?: Function): Observable<any> {
    options.params = options.params.append('XDEBUG_SESSION_START', 'PHPSTORM');

    return this._$http.get<T>(this.serviceUrl + options.endPoint, {
      headers: options.headers,
      params: options.params
    }).pipe(
      map((value: IResponse) => {
        dataModel.next(value.data);
        this._dataModelService.PAGINATION.next(value.pagination);
        if (callback !== undefined) {
          callback(value);
        }

        return value;
      }),
      finalize(this._final),);

  }

  public view(options?: ICommunicatorOptions, dataModel?: BehaviorSubject<T>, callback?: Function): Observable<any> {

    return this._$http.get<T>(this.serviceUrl + options.endPoint).pipe(
      map((value: any) => {
        dataModel.next(value.data);

        console.log(value, dataModel.value);
        if (callback !== undefined) {
          callback(value);
        }
        return value;
      }),
      finalize(this._final),);
  }

  public post(obj: T, options?, form?: FormGroup): Observable<any> {

    options.headers = new HttpHeaders();
    options.headers = options.headers.append('Accept', 'application/json');
    options.headers = options.headers.append('Content-Type', 'application/json');

    return this._$http.post<T>(this._serviceUrl + options.endPoint, obj, {
      headers: options.headers,
      params: options.params
    }).pipe(
      map((value: any) => {
        this._mapAnswer(value as IResponse);
        return value;
      }),
      finalize(this._final),
      catchError((err) => {
        console.log(err);
        if (!!err && !!err.error && !!err.error.data && !!err.error.data.errors) {
          const errors = err.error.data.errors;
          this._iterateErrors(errors, form);
        }

        return observableThrowError(error => {
          return error;
        });

      }),);

  }

  public put(obj: T, options: ICommunicatorOptions): Observable<any> {

    return this._$http.put<T>(this.serviceUrl + options.endPoint + '/' + options.id, obj).pipe(
      map((value: any) => {
        this._mapAnswer(value as IResponse);
        return value;
      }),
      finalize(this._final),
      catchError((err) => {
        console.log(err);
        if (!!err && !!err.error && !!err.error.data && !!err.error.data.errors) {
          const errors = err.error.data.errors;
          if (!!options.form) {
            this._iterateErrors(errors, options.form);
          } else {
            this._iterateErrorsWithoutForm(errors);
          }
        }

        return observableThrowError(error => {
          return error;
        });

      }),);

  }

  public delete(options?) {
    return this._$http.delete<T>(this.serviceUrl + options.endPoint + '/' + options.id, {
      headers: options.headers || {},
      params: options.params || {}
    }).pipe(
      map((value: any) => {
        console.log('----------------');
        console.log(value);
        console.log('----------------');
        this._mapAnswer(value as IResponse);
        return value;
      }),
      finalize(this._final),);

  }


  private _mapAnswer(value: IResponse, options?: IMapAnswerOptions<T>) {

    if (value && value.success) {

      this._messageService.addMessage({
        text: 'Success: ' + value.success,
        status: 'hint'
      });

      console.log(options);
      if (value.data && !!options && !!options.dataModel) {
        if (isArray(value.data)) {
          options.dataModel.pagination.next(value.pagination);
        }
      }
    }
    return value;
  }

  private _iterateErrors(errors?, form?) {
    for (const key in errors) {

      if (errors.hasOwnProperty(key)) {
        const val = errors[key];
        if (isObject(val)) {

          this._messageService.addMessage({
            text: key,
            status: 'hint'
          });

          this._iterateErrors(val, form.get(key));

        } else if (isString(val)) {
          try {
            this._messageService.addMessage({
              text: key + ': ' + val,
              status: 'hint'
            });

            if (form instanceof FormControl) {
              form.setErrors({'error': true});
            }
          } catch (e) {
          }
        }
      }
    }
  }

  private _iterateErrorsWithoutForm(errors?) {
    for (const key in errors) {

      if (errors.hasOwnProperty(key)) {
        const val = errors[key];
        if (isObject(val)) {

          this._messageService.addMessage({
            text: key,
            status: 'hint'
          });

          this._iterateErrors(val);

        } else if (isString(val)) {
          try {
            this._messageService.addMessage({
              text: key + ': ' + val,
              status: 'hint'
            });

          } catch (e) {
          }
        }
      }
    }
  }

  private _final() {

  }

  get serviceUrl(): string {
    return this._serviceUrl;
  }

  set serviceUrl(value: string) {
    this._serviceUrl = value;
  }


  toFormData(e, htmlFormElem: HTMLFormElement, method: string, endpoint: string) {
    e.preventDefault();

    const native = htmlFormElem as any;
    const formData = new FormData();
    const fileInput = native.elements.photo;


    formData.append('username', native.elements.username.value);
    formData.append('password', native.elements.password.value);
    // formData.append('active', native.elements.active.value);
    formData.append('level', native.elements.level.value);
    formData.append('photo', fileInput.files[0]);


    this.uploadFile({
      server: AppConstants.SERVER,
      endpoint: endpoint,
      method: method,
      data: formData,
      callback: (text) => {
        const json = JSON.parse(text);
        console.log(json);

        const success = json.success;
        this._messageService.addMessage({
          text: 'Success: ' + success,
          status: 'hint'
        });
      }
    });

    return false;
  }

  uploadFile(request: any) {

    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (!!request.callback && typeof request.callback === 'function') {
          request.callback(xhr.responseText);
        }
      }
    };

    xhr.open(request.method, request.server + request.endpoint, true);
    xhr.setRequestHeader('Accept', 'application/json');
    const token = localStorage.getItem('token');
    xhr.setRequestHeader('Authorization', 'Bearer ' + token);

    if (!!request.data) {
      xhr.send(request.data);
    } else {
      xhr.send();
    }
  }


}
